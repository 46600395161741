div.button-arrow-right > a {
    color: $primary;
    font-weight: 700;
    @extend .button;

    &:before {
        content: $icon--arrow-right;
        font-family: $icomoon-font-family;
        font-size: 1rem;
        margin-right: .5rem;
        color: inherit;
    }
}

div.primary-button-arrow-right > a {
    color: $white;
    background-color: $secondary;
    font-weight: 700;
    min-width: 18rem;
    text-align: center;
    @extend .button;
    @extend .button--filled;

    &:before {
        content: $icon--arrow-right;
        font-family: $icomoon-font-family;
        font-size: 1rem;
        margin-right: .75rem;
        color: inherit;
    }
}

.button {
    display: inline-block;
    border-radius: $border-radius-button;
    text-transform: uppercase;

    i + span {
        margin-left: .35rem;
    }

    span {
        font-weight: $font-weight-bold;
    }

    i, span {
        color: inherit;
    }

    &--white {
        i, span {
            color: $white;
        }
    }

    &--border, &--filled, &--section {
        padding: .5rem 1.5rem;
    }

    &.button {
        &--border {
            background-color: transparent;
            border: 3px solid $dark-gray;
            box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
            transition: box-shadow .25s ease-in-out;
            margin-bottom: 10px;

            &:hover {
                &.button--primary {
                    background-color: $primary;
                    color: $white;
                    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
                }
            }
        }

        &--filled {
            border: 3px solid $white;
            box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
            transition: box-shadow .25s ease-in-out;
            margin-bottom: 10px;

            i + span {
                margin-left: .75rem;
            }

            &:hover {
                box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            }
        }

        &--primary {
            &.button--filled {
                background-color: $primary;
                color: $white;
            }

            &.button--border {
                border-color: $primary;
                color: $primary;
            }
        }

        &--secondary {
            &.button--filled {
                background-color: $secondary;
                color: $white;

                &:hover {
                    background-color: darken($secondary, 5%);
                }
            }

            &.button--border {
                border-color: $secondary;
                color: $secondary;
            }
        }

        &--section {
            background-color: $primary;
            color: $white;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            i + span {
                margin-left: .5rem;
            }

            span {
                font-size: 1rem;
            }
        }
    }
}
