.footer {
    &--simplified {
        padding: 4rem 0;

        ul {
            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: flex-start;
            }

            li {
                + li {
                    @include media-breakpoint-up(lg) {
                        margin-left: 1rem;
                    }

                    @include media-breakpoint-down(md) {
                        margin-top: 1rem;
                    }
                }

                a {
                    color: $white;
                }
            }
        }
    }

    &--main {
        background-color: $dark-gray;
        padding: 2rem 0 1rem;

        @include media-breakpoint-down(md) {
            padding: 2rem 0 6rem 0;
        }

        .footer-link {
            @include media-breakpoint-up(lg) {
                display: flex;
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            + .footer-link {
                margin-top: 1rem;
            }

            &__label, &__content {
                color: $white;
                font-weight: $font-weight-light;
                width: 100%;

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }

            &__label {
                text-transform: uppercase;

                @include media-breakpoint-down(md) {
                    font-weight: bold;
                    margin-bottom: .5rem;
                }

                @include media-breakpoint-up(lg) {
                    width: 100%;
                    max-width: 40%;
                    flex-basis: 40%;
                }

                i.icon {
                    display: inline-block;
                    margin-right: .25rem;
                    min-width: 20px;
                }

                strong {
                    font-weight: bold;
                }
            }

            &__content {
                @include media-breakpoint-up(lg) {
                    width: 100%;
                    max-width: 60%;
                    flex-basis: 60%;
                }
            }
        }

        .footer {
            &__nav {
                &-wrapper {
                    display: flex;
                    padding-top: 1rem;
                    border-top: 1px solid rgba(255, 255, 255, .1);

                    .footer__scroll-to-top {
                        flex-shrink: 0;
                        margin-left: 50px;
                    }
                }
                &--links {
                    flex-grow: 1;

                    @media screen and (max-width: 486px) {
                        .footer__nav-container {
                            margin-bottom: 40px;
                        }
                    }

                    @media screen and (min-width: 487px) {
                        width: 100%;
                        display: flex;
                        gap: 40px;
                        flex-wrap: wrap;
                    }
                }
            }

            &__description {
                color: $primary;
                margin-bottom: 3em;

                p, strong, span {
                    color: inherit;
                }
            }

            &__menu--links {
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                li {
                    color: $white;
                    font-weight: $font-weight-light;
                    display: flex;
                    line-height: 100%;

                    + li {

                        @include media-breakpoint-down(md) {
                            margin-top: .75rem;
                        }

                        @include media-breakpoint-up(lg) {
                            margin-left: .5rem;

                            &:before {
                                content: '';
                                display: inline-block;
                                border-left: 1px solid $white;
                                padding-right: .5rem;
                            }
                        }
                    }

                    a {
                        color: inherit;
                    }
                }
            }

            &__menu--links--list {
                align-items: flex-start;
                flex-direction: column;

                li {
                    color: $white;
                    font-weight: $font-weight-light;
                    display: flex;
                    line-height: 100%;

                    + li {
                        margin-top: .75rem;
                    }

                    a {
                        color: inherit;
                        &:focus,
                        &:hover {
                            color:$primary;
                        }
                    }
                }
            }

            &-link__content {
                p {
                    color: $white;
                }
            }

            &__scroll-to-top {
                width: 3rem;
                height: 3rem;
                border: 2px solid $primary;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: $primary;
                }
            }
        }
    }

    &__content {
        padding-bottom: 2em;

        &--data {
            margin-bottom: 3em;
        }
    }

    &__certificates {
        p {
            color: $white;
        }

        &--heading {
            text-transform: uppercase;
            font-weight: bold;
        }

        &--text {
            padding-bottom: 1.5em;
        }

        img {
            margin-bottom: 10px;
        }
    }

    &__social {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 15px 0 40px 0;

        @include media-breakpoint-up(lg) {
            padding: 15px 30px 40px 18px;
        }

        &--icon {
            margin-right: 24px;
        }
    }

    &__appointment {
        .button {
            display: inline-block;
            margin-bottom: 1em;
        }
    }

    .has-white-link {
        a {
            color: $white;
        }
    }

    .bold {
        font-weight: bold;
    }
}
