$icomoon-font-family: "Binect-Icons";
$icomoon-font-path: "../fonts/icons";

$icon--xing: "\e909";
$icon--linkedin: "\e90a";
$icon--chevron-down: "\e908";
$icon--email: "\e907";
$icon--play: "\e900";
$icon--check: "\e901";
$icon--arrow-right: "\e902";
$icon--arrow-top: "\e903";
$icon--phone: "\e904";
$icon--pin: "\e905";
$icon--support: "\e906";
$icon--search: "\e90d";
$icon--ring: "\e90e";
$icon--info: "\e90f";
$icon--login: "\e910";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z54s7b');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z54s7b#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?z54s7b') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?z54s7b') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?z54s7b#{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon--"], [class*=" icon--"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon--info:before {
    content: $icon--info;
}

.icon--xing:before {
    content: $icon--xing;
}

.icon--linkedin:before {
    content: $icon--linkedin;
}

.icon--chevron-down:before {
    content: $icon--chevron-down;
}

.icon--email:before {
    content: $icon--email;
}

.icon--play:before {
    content: $icon--play;
}

.icon--check:before {
    content: $icon--check;
}

.icon--arrow-right:before {
    content: $icon--arrow-right;
}

.icon--arrow-top:before {
    content: $icon--arrow-top;
}

.icon--phone:before {
    content: $icon--phone;
}

.icon--pin:before {
    content: $icon--pin;
}

.icon--support:before {
    content: $icon--support;
}

.icon--search:before {
    content: $icon--search;
}

.icon--ring:before {
    content: $icon--ring;
}

.icon--login:before {
    content: $icon--login;
}