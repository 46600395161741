.shipping-test-form {
    .hidden {
        display: none !important;
    }

    .elementor-section-wrap .elementor-section.elementor-section-boxed > .elementor-container {
        padding: 0;

        p, a, span, h1, h2, h3, h4, h5, h6, ul li {
            color: $white;
        }
    }

    textarea, input[type="text"], input[type="email"] {
        border-radius: 20px;
        appearance: none;
        background-color: $white;
        border: none;
        width: 100%;
        color: $dark-gray;
        outline: none;

        + input[type="text"], + input[type="email"], + textarea {
            margin-top: 1rem;
        }
    }

    textarea {
        padding: 1rem 1.5rem;
        resize: none;

        &[name="sender"] {
            min-height: 10rem;
            background-color: $gray;
            cursor: not-allowed;
            margin-bottom: .75rem;
        }

        &[id="content"] {
            min-height: 23.25rem;
            margin-bottom: .75rem;
        }
    }

    input[type="checkbox"] {
        appearance: none;
        width: 1.25rem;
        height: 1.25rem;
        background-color: $white;
        border-radius: 6px;
        position: relative;
        outline: none;

        &:checked {
            &:before {
                content: '';
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                background: url('../images/checkmark.svg') no-repeat center center;
                background-size: contain;
            }
        }

        + span {
            margin-left: .5rem;
            max-width: 90%;
        }
    }

    label {

        &.checkbox-label {
            color: $white;
        }

        span {
            color: inherit;
        }

        &.checkbox-label {
            display: flex;
            align-items: center;
        }
    }

    &__submit-button {
        padding: .75rem 1rem;
    }

    .recaptcha-note {
        &__text, &__text a {
            color: $white;
            font-size: $font-size-sm;
        }

        &__text a {
            text-decoration: underline;
        }
    }

    .link {
        color: $white;
        margin: 1rem 0;

        a {
            &:link,
            &:visited,
            &:hover,
            &:active,
            &:focus {
                color: $white;
                text-decoration: underline;
            }
        }
    }
}
