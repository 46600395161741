.logos {
    display: flex;
    align-items: center;
    min-height: 5.5rem;

    &--space-between {
        justify-content: space-between;
    }

    .logo {
        &__image {
            //max-height: 4.5rem;
            width: auto;

            + .logo__image {
                margin-left: 1rem;
            }
        }
    }
}
