.tooltip {
    padding: .4rem .5rem;
    border-radius: .3rem;
    background: rgb(65, 182, 230);
    color: #fff;
    position: absolute;
    bottom: 1.8rem;
    left: -0.6rem;
    display: none;
    white-space: nowrap;

    &-wrapper {

        > p {
            display: inline;
        }
    }

    &-button {
        color: rgb(65, 182, 230);
        font-size: .9rem;

        &-wrapper {
            display: inline-block;
            position: relative;

            p {
                display: inline-block;
                position: relative;
            }
        }

        &:hover {
            cursor: pointer;

            + .tooltip {
                display: block;
                opacity: 1;
            }
        }

        i {
            font-size: .9rem;
        }
    }    

    &_caret {
        position: absolute;
        bottom: -.42rem;
        left: 0;
        display: inline-block;
        margin-left: 0.6rem;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.5em solid rgb(65, 182, 230);
        border-right: 0.5em solid transparent;
        border-bottom: 0;
        border-left: 0.5em solid transparent;
    }
}


// .tooltip {

//     &-button {
//         color: rgb(65, 182, 230);
//         margin-top: 5px;
//         font-size: .9rem;

//         &:hover {
//             cursor: pointer;

//             + .tooltip {
//                 display: block;
//                 opacity: 1;
//             }
//         }
//     }

//     &_caret {
//         position: absolute;
//         bottom: -.42rem;
//         left: 0;
//         display: inline-block;
//         margin-left: 0.6rem;
//         vertical-align: 0.255em;
//         content: "";
//         border-top: 0.5em solid rgb(65, 182, 230);
//         border-right: 0.5em solid transparent;
//         border-bottom: 0;
//         border-left: 0.5em solid transparent;
//     }
// }