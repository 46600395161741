.teaser {

    &.teaser--post, &.teaser--case {
        a {
            display: inline-block;
            width: 100%;
        }
    }

    &--post {
        @include media-breakpoint-up(md) {
            .main-content--blog & {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
        }

        .teaser {
            &__image {
                display: inline-block;
                width: 100%;
                height: 15rem;
                padding-bottom: 60%;
                border-radius: $border-radius-default;
                background-size: cover;
                background-position: center center;
            }

            &__headline {
                font-weight: $font-weight-light;
                color: $dark-gray;
                font-size: $font-size-lg;
                margin-top: 2rem;

                @include media-breakpoint-up(md) {
                    min-height: 5.5rem;
                }
            }

            &__content {
                @include media-breakpoint-up(md) {
                    margin-top: 2rem;
                    //min-height: 5.5rem;
                }

                @include media-breakpoint-down(md) {
                    margin-top: 1rem;
                }
            }

            &__link {
                margin-top: 1.5rem;
                color: $primary;
                margin-bottom: 2rem;
            }
        }
    }

    &--case {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        body:not(.browser--internet-explorer) & {
            height: 100%;
        }

        .teaser {
            &__image {
                width: 100%;
                height: 15rem;
                border-radius: $border-radius-default;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                background-size: cover;
                background-position: center center;

                .logo {
                    max-width: 17.75rem;
                    max-height: 10rem;
                }
            }

            &__headline {
                font-weight: $font-weight-light;
                color: $dark-gray;
                font-size: $font-size-lg;
                margin-top: 2rem;
            }

            &__content {
                margin-top: 2rem;
                min-height: 7rem;
            }

            &__link {
                margin-top: 2rem;
                color: $primary;
                margin-bottom: 2rem;
            }
        }
    }

    &__heading {
        padding-top: 3rem;
        margin-bottom: 3rem;
    }
}
