p, h1, h2, h3, h4, h5, h6, div.button-arrow-right, div.primary-button-arrow-right {
    font-weight: $font-weight-light;
    margin-bottom: 0;

    + p, + div.button-arrow-right, + div.primary-button-arrow-right {
        margin-top: 1rem;
    }
}

.main-content {
    h1, h2, h3, h4, h5, h6, .elementor-heading-title {
        line-height: $line-height-base;

        &:last-child {
            margin: 1rem 0 0 0;

            @include media-breakpoint-down(md) {
                margin: 1rem 0;
            }
        }
    }

    h1, h2, h3, h4, h5, h6, .elementor-heading-title, p {
        + h1, + h2, + h3, + h4, + h5, + h6, + .elementor-heading-title {
            margin-top: 2rem;
        }
    }

    p a, p, a {
        hyphens: auto;
    }

    @include media-breakpoint-down(md) {
        h1 {
            font-size: calc(#{$h1-font-size} * .75);
        }

        h2 {
            font-size: calc(#{$h2-font-size} * .75);
        }

        h3 {
            font-size: calc(#{$h3-font-size} * .75);
        }

        h4 {
            font-size: calc(#{$h4-font-size} * .75);
        }

        h5 {
            font-size: calc(#{$h5-font-size} * .75);
        }

        h6 {
            font-size: calc(#{$h6-font-size} * .75);
        }
    }
}

button span.e-n-tab-title-text {
    color:  var(--n-tabs-title-color);
}
button:hover span.e-n-tab-title-text,
button[aria-selected="true"] span.e-n-tab-title-text {
    color: var(--n-tabs-title-color-hover);
}

.elementor-widget-icon-box .elementor-icon-box-title span {
    color: var(--e-global-color-primary);
}

.content-wrapper {
    &.text-white {
        p, a, span, li {
            color: $white;
        }
    }

    p, a, span, ul {
        + p, + a, + span, + ul {
            margin-top: 1rem;
        }
    }

    ul:not(.list--with-arrow-right) {
        @extend .list--with-check-icons;
    }
}

.elementor-text-editor {
    p + ul, a + ul {
        margin-top: 1rem;
    }

    ul:not(.list--with-check-icons):not(.list--alternating) {
        padding-left: 1.125rem;
    }
}

.list {
    &--with-check-icons, &--with-arrow-right {
        list-style: none;
        padding: 0;
        margin: 0 0 1rem;

        li {
            position: relative;
            padding-left: 2rem;
            line-height: $line-height-base;
            font-weight: $font-weight-light;

            &:before {
                font-family: $icomoon-font-family;
                content: '';
                color: inherit;
                font-size: 1rem;
                position: absolute;
                left: 0;
                top: 0;
            }

            + li {
                margin-top: 1rem;
            }
        }
    }

    &--with-check-icons li:before {
        content: $icon--check;
    }

    &--with-arrow-right li:before {
        content: $icon--arrow-right;
    }

    &--alternating {
        list-style: none;
        padding: 0;
        margin: 1rem 0 0;

        li {
            padding: .25rem .5rem;

            &:nth-child(odd) {
                background-color: $light-gray;
            }

            &:nth-child(even) {
                background-color: $white;
            }
        }
    }
}

.text {
    &-green {
        color: $secondary;
    }
}
