.binect-slider.swiper-container,
.testimonials .swiper-container {
  .swiper-slide, .swiper-wrapper {
    height: auto !important;
  }

  .swiper-pagination {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      border: 1px solid $dark-gray;
      background-color: transparent;

      &.swiper-pagination-bullet-active {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

.binect-slider {
  .swiper-slide {
    //padding: 0 .5rem;
  }
}

.binect-team-slider {
  &__item {
      width: 25%;
  }

  &__content-wrapper {
    padding: 10px;
    text-align: center;
  }

  &__image {
    width: 150px;
    height: 150px;
    margin: 0 auto;

    img {
      height: auto;
      max-width: 100%;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 20px !important;
      box-sizing: border-box;
      transition-duration: 0.3s;
      margin: 0px;
    }
  }

  &__title {
    * {
      margin: 0px;
      font-weight: 400;
      color: var(--e-global-color-primary);
    }
  }

  &__content {
    font-weight: 300;
  }
  .swiper-wrapper {
    margin-bottom: 1rem;
  }

}
