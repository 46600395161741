.search-results {
    padding-bottom: 2rem;

    &__headline {
        margin-bottom: 1rem;
    }

    &__result {

        + .search-results__result {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid $border-color;
        }
    }

    .search-result {
        &__link {
            margin-top: 1rem;
            display: block;
        }

        &__title {
            font-size: 1.25rem;
        }
    }
}
