.testimonials {
    .swiper-container {
        padding-bottom: 2rem;
    }

    .testimonial {
        > .row > div {
            padding-top: 4rem;
            padding-bottom: 4rem;

            &:first-child {
                padding-right: 2rem;
                display: flex;
                align-items: center;

                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }
            }

            + div {
                padding-left: 4rem;
                border-left: 1px solid $dark-gray;

                @include media-breakpoint-down(md) {
                    border-left: 0;
                    padding-left: 0;
                }
            }
        }

        &__text {
            color: $dark-gray;
            font-size: $font-size-lg;
            font-weight: lighter;
        }

        &__name, &__position {
            display: block;
            color: $primary;
        }

        &__name {
            font-weight: $font-weight-bold;
        }
    }
}

.single-testimonial {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
        display: block;
        text-align: center;
    }

    &__image {
        width: 22.5rem;
        min-width: 22.5rem;
        height: 22.5rem;
        min-height: 22.5rem;
        display: inline-block;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;

        @include media-breakpoint-down(md) {
            width: 12.5rem;
            min-width: 12.5rem;
            height: 12.5rem;
            min-height: 12.5rem;
        }

        &--logo {
            background-size: auto;
            background-repeat: no-repeat;
        }
    }

    &__content {
        padding: 2rem 0 2rem 6rem;

        @include media-breakpoint-down(md) {
            padding: 2rem 0 0;
        }
    }

    &__text, &__name, &__position {
        color: $white;
        display: block;
    }

    &__text {
        .section--single-testimonial.bg-color--light-gray & {
            color: $dark-gray;
        }
    }

    &__name, &__position {
        .section--single-testimonial.bg-color--light-gray & {
            color: $primary;
        }
    }

    &__text {
        font-size: $font-size-xl;
        font-weight: $font-weight-light;
        font-style: italic;
        line-height: $line-height-base;
        margin-bottom: 2rem;
        position: relative;

        @include media-breakpoint-down(md) {
            font-size: $font-size-md;
        }

        &:before {
            content: '';
            position: absolute;
            top: -1rem;
            left: -.75rem;
            display: inline-block;
            width: 200px;
            height: 140px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOTkuOTA0IiBoZWlnaHQ9IjE0MC4wNCI+PHBhdGggZGF0YS1uYW1lPSJQZmFkIDI2MCIgZD0iTTkzLjI3MSA3My40OTRMMzcuNjgzIDE0MC4wNEgwbDI3Ljc5NC02My4wNzFMNDMuMjk0LjAwMWg2NC42NzV6bTkxLjkzNSAwbC01NS41ODggNjYuNTQ2SDkxLjkzNWwyOC4zMjktNjUuMjA5TDEzNS4yMy4wMDFoNjQuNjc1eiIgZmlsbD0iI2ZmZiIgb3BhY2l0eT0iLjI2NiIvPjwvc3ZnPg==");
            background-size: cover;

            .section--single-testimonial.bg-color--light-gray & {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTQxcHgiIHZpZXdCb3g9IjAgMCAyMDAgMTQxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aW1hZ2UgKDMpPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgb3BhY2l0eT0iMC4yNjYiPgogICAgICAgIDxnIGlkPSJpbWFnZS0oMykiIGZpbGw9IiM1MzU2NUEiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik05My4yNzEsNzMuNDk0IEwzNy42ODMsMTQwLjA0IEwwLDE0MC4wNCBMMjcuNzk0LDc2Ljk2OSBMNDMuMjk0LDAuMDAxIEwxMDcuOTY5LDAuMDAxIEw5My4yNzEsNzMuNDk0IFogTTE4NS4yMDYsNzMuNDk0IEwxMjkuNjE4LDE0MC4wNCBMOTEuOTM1LDE0MC4wNCBMMTIwLjI2NCw3NC44MzEgTDEzNS4yMywwLjAwMSBMMTk5LjkwNSwwLjAwMSBMMTg1LjIwNiw3My40OTQgWiIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
            }
        }
    }

    &__name {
        font-weight: $font-weight-bold;
    }

    &__position {
        font-weight: $font-weight-light;
    }
}
.section--testimonial-quote {

    &.bg-color--light-gray {
        .single-testimonial {
            &__text {
                &::before {
                    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTQxcHgiIHZpZXdCb3g9IjAgMCAyMDAgMTQxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aW1hZ2UgKDMpPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgb3BhY2l0eT0iMC4yNjYiPgogICAgICAgIDxnIGlkPSJpbWFnZS0oMykiIGZpbGw9IiM1MzU2NUEiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik05My4yNzEsNzMuNDk0IEwzNy42ODMsMTQwLjA0IEwwLDE0MC4wNCBMMjcuNzk0LDc2Ljk2OSBMNDMuMjk0LDAuMDAxIEwxMDcuOTY5LDAuMDAxIEw5My4yNzEsNzMuNDk0IFogTTE4NS4yMDYsNzMuNDk0IEwxMjkuNjE4LDE0MC4wNCBMOTEuOTM1LDE0MC4wNCBMMTIwLjI2NCw3NC44MzEgTDEzNS4yMywwLjAwMSBMMTk5LjkwNSwwLjAwMSBMMTg1LjIwNiw3My40OTQgWiIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
                }
            }
        }
    }

    .single-testimonial {
        &__text, &__name, &__position {
            line-height: 1.4;
        }

        &__text {
            color: $dark-gray;
            &::before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOTkuOTA0IiBoZWlnaHQ9IjE0MC4wNCI+PHBhdGggZGF0YS1uYW1lPSJQZmFkIDI2MCIgZD0iTTkzLjI3MSA3My40OTRMMzcuNjgzIDE0MC4wNEgwbDI3Ljc5NC02My4wNzFMNDMuMjk0LjAwMWg2NC42NzV6bTkxLjkzNSAwbC01NS41ODggNjYuNTQ2SDkxLjkzNWwyOC4zMjktNjUuMjA5TDEzNS4yMy4wMDFoNjQuNjc1eiIgZmlsbD0iI2ZmZiIgb3BhY2l0eT0iLjI2NiIvPjwvc3ZnPg==");
            }
        }

        &__name, &__position {
            color: $primary;
        }
    }
    &--headline {
        color: $primary;
    }

    &.bg-image--blue {

        .section--testimonial-quote--headline {
            color: $white;
        }

        .single-testimonial {
            &__text {
                color: $white;
            }

            &__name, &__position {
                color: $white;
            }
        }



        .swiper-container .swiper-pagination .swiper-pagination-bullet {
            background-color: $dark-gray;

            &.swiper-pagination-bullet-active {
                background-color: $white;
            }
        }
    }
}
