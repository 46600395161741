// Settings
// ==================================================
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 40px;
$hamburger-layer-height: 5px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $primary;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
        boring
);
