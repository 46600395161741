.case-studies {
    padding-bottom: 4rem;

    .swiper-slide {
        padding: 0 1rem;
    }

    &__categories {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0 0 2rem;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &__category {
        padding: 0 .5rem;

        &.is-active a {
            background-color: $primary;
            color: $white;
        }

        a {
            padding: .5rem;
            line-height: 100%;
            display: inline-block;
        }
    }
}
