@media print {

    body.single.single-post, body.single.single-case_studies {
      background-attachment: initial;
      background-repeat: no-repeat;
      background-size: 100vw 100vh;
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @extend .gradient--white;
      }
    }

    .header--main .header__nav--main {
      justify-content: flex-end;
    }

    body.single.single-post .post-wrapper,
    body.single.single-case_studies .post-wrapper {
      display: block;
    }

    body.single.single-post .post-wrapper__column--sidebar,
    body.single.single-case_studies .post-wrapper__column--sidebar {
      max-width: 100%;
    }

    .header__nav-wrapper,
    .header--main .header__nav--add,
    #usercentrics-root,
    .section--other-posts,
    footer,
    .footer__appointment,
    .footer__description,
    .footer__certificates,
    .footer__social,
    .footer__nav-wrapper {
      display: none !important;
    }

    .header--main {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    a:link {
      text-decoration: none;
      color: $dark-gray;
    }

    .contact-person {
      background-color: transparent;
      color: $dark-gray;
      padding: 15px;
      break-before: page;

      &__heading {
        display: block;
        font-size: 40px;
        margin-bottom: 40px;
        margin-top: 100px;
        color: $primary;
      }

      &__image {
          width: 250px;
          height: 250px !important;
          padding-bottom: 0px !important;
          background-image: none;

          img {
            display: block !important;
            border-radius: 100%;
          }
        }

        &__name {
          color: $dark-gray;
        }

        &__contact {
          display: none;
        }

        &__email {
          color: $dark-gray;
        }

        &__socials {
          display: block;

          a {
            display: block;
            margin-bottom: 10px;
          }

          a[href]::after {
            content: " "attr(href)"";
            color: $dark-gray;
            background-color: inherit;
            font-style: italic;
          }
        }

        &__social-icon {
          color: $primary !important;

          &--email {
            display: none !important;
          }
        }

        &__qr-code {
          display: block;

          &--info {
            font-size: 24px;
            margin-top: 60px;
            color: $primary;
            margin-bottom: 30px;
          }

          &--image {
            display: block !important;
          }
        }
    }

    .elementor-spacer {
      display: none;
    }

  .footer {
    &--main {
      background-color: transparent;
      color: $dark-gray;
      padding-left: 14px;
      border-top: 2px dotted $dark-gray;
      margin-top: 30px;
      padding-top: 30px;

      .footer-link {
        display: flex;
        flex-direction: row;

        &__label {
          color: $dark-gray;
          width: 100%;
          max-width: 40%;
          flex-basis: 40%;
        }
        &__content {
          color: $dark-gray;
        }
      }
    }

    .icon {
      color: $black;
    }

    .has-white-link a {
      color: $dark-gray;
    }
  }
}