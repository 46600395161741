.binect-accordion {
    &__item {
        appearance: none;

        + .binect-accordion__item {
            border-top: 1px solid $border-color;
        }

        &[open] .binect-accordion__title {
            color: $primary;

            &:after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC45MjgiIGhlaWdodD0iMi4xNyI+PHBhdGggZD0iTTE4LjkyOCAyLjE3SDBWMGgxOC45Mjh6IiBmaWxsPSIjNTM1NjVhIi8+PC9zdmc+");
            }
        }
    }

    &__title {
        padding: 1.5rem 0;
        appearance: none;
        outline: none;
        position: relative;

        > h4:first-child {
            margin-top: 0;
            margin-bottom: 0;
        }

        &:first-of-type {
            list-style-type: none;
        }

        h4 {
            line-height: $line-height-base;

            @include media-breakpoint-up(md) {
                max-width: calc(100% - 1.5rem);
                padding-right: 2rem;
            }

            @include media-breakpoint-down(md) {
                max-width: calc(100% - 1.5rem);
                font-size: $font-size-md;
            }
        }

        &::marker, &::marker, &::-webkit-details-marker {
            display: none !important;
        }

        &:after {
            content: '';
            display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS40NDEiIGhlaWdodD0iMjIuNzczIj48cGF0aCBkPSJNMTEuOTI0IDEwLjAxaDkuNTE4djIuNDA2aC05LjUxOHYxMC4zNTdoLTIuNlYxMi40MThIMFYxMC4wMWg5LjMyNFYwaDIuNnoiIGZpbGw9IiM1MzU2NWEiLz48L3N2Zz4=");
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            @include media-breakpoint-up(md) {
                width: 1.375rem;
                height: 1.375rem;
            }

            @include media-breakpoint-down(md) {
                width: 1rem;
                height: 1rem;
            }
        }
    }

    &__content-wrapper {
        display: flex;
        padding-bottom: 1.5rem;

        &--has-two-cols {
            @include media-breakpoint-up(md) {
                margin: 0 -1rem;

                .binect-accordion__content {
                    width: 100%;
                    max-width: 50%;
                    flex-basis: 50%;
                    padding: 0 1rem;
                }
            }

            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
                flex-wrap: wrap;

                .binect-accordion__content {
                    width: 100%;
                    flex-basis: 100%;

                    &--2 {
                        margin-bottom: 2rem;
                    }
                }
            }
        }

        &--has-one-col {
            .binect-accordion {
                &__content {
                    width: 100%;
                    flex-basis: 100%;
                }
            }
        }

        &--has-no-pad {
            .elementor-container {
                padding: 0 !important;
            }

            .elementor-widget-wrap {
                padding: 10px !important;
            }

        }
    }
}