.steps {
    @include media-breakpoint-down(md) {
        &__step {
            + .steps__step {
                margin-top: 2rem !important; // Default elementor styling would override this line
            }
        }
    }

    &__step-image {
        width: 100%;
    }

    &__step-description {
        margin-top: 1rem;
        position: relative;
        min-height: 7rem;

        > *:not(.steps__step-number) {
            z-index: 5;
            position: relative;
        }

        p, strong, i {
            font-weight: $font-weight-light;
            font-size: $font-size-lg;
        }
    }

    &__step-number {
        font-size: 8rem;
        font-weight: bold;
        color: $primary;
        opacity: .25;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 80%; // To remove additional spacing caused by the line height
        z-index: 1;
    }
}
