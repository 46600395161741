.menu-item--search {
    position: relative;

    a {
        padding-right: 0;

        &:before {
            display: none !important;
        }
    }
    @include media-breakpoint-down(md) {
        border-top: 1px solid $white;
        padding-top: 20px !important;
    }

    .search-wrapper {
        position: absolute;
        top: calc(100% + .5rem);
        right: 0;

        @include media-breakpoint-down(md) {
            right: auto;
            left: -3px;
            width: 100%;

            .search__input--text {
                width: 100%;
            }

            .search__text {
                width: 100%;
            }
        }
    }

    .search-wrapper,
    .search-wrapper--mobile {
        display: none;

        &.is-active {
            display: block;
        }

        .search {
            &__inner {
                display: flex;
            }

            &__input {
                input {
                    appearance: none;
                    border: none;
                    border-radius: 0;
                    outline: none;
                    height: 2.5rem;
                }

                input[type="text"] {
                    padding: 0 .75rem;
                    background-color: $light-gray;

                    &:hover, &:active, &:focus {
                        border: 1px solid rgba(0, 0, 0, .125);
                    }
                }

                input[type="submit"] {
                    padding: 0 .5rem;
                    border-left: 1px solid rgba(0, 0, 0, .125);
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    #search-icon {
        @include media-breakpoint-down(lg) {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}
