.navigation.pagination {
    margin: 2rem 0 1rem;

    .nav-links {
        display: flex;
        align-items: center;

        a, span {
            font-size: $font-size-md;
        }

        a, span {
            + a, + span {
                margin-left: .75rem;
            }
        }

    }
}
