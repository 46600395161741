form {
    margin: 0;
}

.input-wrapper {
    &--label {
        position: relative;

        input {
            padding: 1.35rem 1rem .75rem;
            border: none;
            outline: 0;
            box-shadow: none;
            height: 3.75rem;
            position: relative;
            z-index: 9;
        }

        label {
            position: absolute;
            left: 0;
            width: 100%;
            padding: 0 1rem;
            box-sizing: border-box;
            transition: line-height .3s ease-in-out, height .3s ease-in-out, font-size .3s ease-in-out;
            font-size: .75rem;
            top: 0;
            height: 1.875rem;
            line-height: 1.875rem;
            color: $dark-gray;
            z-index: 10;
            margin: 0;

            &.inactive {
                font-size: 1rem;
                line-height: 3.75rem;
                height: 3.75rem;
            }
        }
    }

    + .input-wrapper {
        margin-top: .75rem;

        &--submit {
            margin-top: 1.5rem;
        }
    }
    &--contact {
        display: none;
    }
}

.input {
    &--text {
        padding: .375rem 1.5rem;
        border-radius: $border-radius-default;
        outline: none;
        border: none;
        width: 100%;

        &:hover, &:focus, &:active {
            background-color: $light-gray;
        }
    }
}

.elementor-widget-container .elementor-form .elementor-form-fields-wrapper {
    padding: 3rem;
    background-color: #F9F9F9;

    @include media-breakpoint-down(md) {
        margin: 1rem 0;
        padding: 1rem;
    }

    input[type="text"], input[type="email"], input[type="tel"], textarea {
        border: 1px solid rgb(216, 222, 228) !important;
        border-radius: 0 !important;
    }

    textarea {
        padding: .5rem;
    }

    [type="submit"], .elementor-button {
        display: inline-block;
        border-radius: $border-radius-button;
        text-transform: uppercase;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
        transition: box-shadow .25s ease-in-out;
        margin: 1rem 0 0;
        padding: .5rem 2.5rem;

        i + span {
            margin-left: .75rem;
        }

        i, span {
            color: $white;
        }

        &:hover {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        }
    }
}

form {
    .uc-text-embedding.g-recaptcha {
        padding: 1rem;

        strong {
            display: block;
        }

        strong, span {
            font-size: .875rem;
        }

        a, .uc-text-embedding-inline-button {
            height: auto !important;
            text-decoration: underline;
            color: $primary;
        }
    }
}
