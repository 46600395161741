.request_link {
  &__info {
    &--row {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    &--icon {
      color: $white;
      margin-right: 2rem;

      svg {
        fill: $white;
      }

      i {
        position: relative;

        &:before {
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
    }

    &--text {
      p {
        color: $white;
      }
    }

    &--heading {
      color: $white;
      margin-bottom: 1.5rem;
    }
  }
}