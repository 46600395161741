// ---- ---- ---- ----
// COLORS
$primary: #41B6E6;
$secondary: #97D700;

$dark-gray: #53565A;
$light-gray: #F4F4F4;
$gray: #DBD8D8;
$border-color: #707070;
// ---- ---- ---- ----
$body-color: $dark-gray;

$section-spacing: 2.375rem;
$mobile-menu-height: 5.5rem;


// ---- ---- ---- ----
// FONTS
$main-font-family: 'Roboto';
$main-font: $main-font-family, sans-serif;

$font-family-base: $main-font;

$h1-font-size: 2.5rem;
$h2-font-size: 2.25rem;

$font-size-base: 1.125rem;
$font-size-md: 1.25rem;
$font-size-lg: 1.5rem;
$font-size-xl: 1.75rem;

$line-height-base: 1.4;
$line-height-lg: 1.6;
$line-height-sm: 1;
// ---- ---- ---- ----


// ---- ---- ---- ----
// BASE
$border-radius-default: 20px;
$border-radius-button: 25px;
// ---- ---- ---- ----


// ---- ---- ---- ----
// RESPONSIVE
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1260px,
);

$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1160px
);

$grid-gutter-width: 32px;
// ---- ---- ---- ----
