.newsletter {
    &__headline, &__content {
        color: $white;

        * {
            color: $white;
        }
    }

    &__headline {
        margin-bottom: 2rem;
    }
}

.elementor-element.newsletter2go {

    form {
        label, input:not([type="checkbox"]) {
            width: 100%;
        }

        .checkbox-inline {
            input {
                margin-right: .5rem;
            }

            input, label {
                display: inline;
            }
        }

        [type="submit"] {
            border-radius: 25px !important;
            padding: .75rem 2rem !important;
        }
    }
}
