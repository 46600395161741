ul.menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

a:hover {
    text-decoration: none;
}

body {
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
    //overflow-x: hidden;

    &.has-active-menu {
        position: relative;
        height: 100%;
        overflow: hidden;
        touch-action: none;
        -ms-touch-action: none;
    }
}

.bg-color {
    &--dark-gray {
        background-color: $dark-gray;
    }

    &--light-gray {
        background-color: $light-gray;
    }

    &--green {
        background-color: $secondary;
    }

    &--white {
        background-color: $white;
    }

    &--primary-transparent {
        background-color: $primary;
        background-blend-mode: color;
    }

    &--secondary-transparent {
        background-color: $secondary;
        background-blend-mode: color;
    }
}

.bg-image {
    &--blue {
        background-image: url('../images/binect-bg--blue.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-position: center center;
    }

    &--gray {
        background-image: url('../images/binect-bg--gray.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-position: center center;
    }

    &--dark-gray {
        position: relative;
        background-color: $light-gray;

        > div {
            z-index: 5;
            position: relative;
        }

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .5;
            background-image: url('../images/binect-bg--gray.jpg');
            background-attachment: fixed;
            background-size: cover;
            background-position: center center;
        }

        &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            @extend .gradient--gray;
        }
    }

    &--gradient-gray {
        position: relative;

        > .container, > div {
            z-index: 10;
            position: relative;
        }

        &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            @extend .gradient--gray;
        }
    }
}

.gradient {
    &--gray {
        background: rgb(244, 244, 244);
        background: -moz-linear-gradient(0deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 0) 100%);
        background: linear-gradient(0deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4f4f4", endColorstr="#f4f4f4", GradientType=1);
    }

    &--white {
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
    }
}

body.single.single-post, body.single.single-case_studies {
    background-attachment: initial;
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    position: relative;

    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @extend .gradient--white;
    }

    > .main-content {
        z-index: 2;
        position: relative;
    }

    .post-wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-left: -1rem;
        margin-right: -1rem;

        &__column {
            width: 100%;

            @include media-breakpoint-down(md) {
                &--sidebar {
                    padding: 1rem;
                }
            }

            @include media-breakpoint-up(lg) {
                &--main {
                    max-width: 70%;
                    flex-basis: 70%;
                }

                &--sidebar {
                    padding: 0 1rem;
                    max-width: 30%;
                    flex-basis: 30%;
                }
            }

            @include media-breakpoint-down(lg) {
                flex-basis: 100%;
            }
        }
    }

    .sidebar {
        &__item {
            + .sidebar__item {
                margin-top: 2rem;
            }
        }
    }

    .main-content > .post-wrapper > .elementor > .elementor-inner {
        width: 100%;
        max-width: map-get($container-max-widths, xl);
        margin: auto;
    }
}

body.single.single-case_studies {
    .post-wrapper {
        &__column {
            &--main {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }
}

#uc-main-banner {
    a, span, p {
        color: $white;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

@include media-breakpoint-down(md) {
    .table-wrapper {
        .elementor-text-editor {
            overflow: auto;
        }
    }
}

.text-error {
    background-color: #cc3e4c;
    color: $white;
    padding: .25rem .5rem;
}

.has-uc-embed.elementor-wrapper.elementor-fit-aspect-ratio {
    height: auto;
    padding: 0;
}

.uc-embedding-container {
    width: 100% !important;

    > img {
        transform: scale(1.4);
    }

    .uc-embedding-wrapper {
        h3 {
            font-size: 1rem;
        }

        .description-text {
            margin: .5rem 0;
        }
    }
}

body.page-template-template-shipping-ok .main-content--registration-ok {
    .elementor-inner {
        p, h1, h2, h3, h4, h5, h6, span, a, li {
            color: $white;
        }
    }
}

.screen-reader-text {
    display: none;
}

@media (min-width: 992px) {
    .ml-50 {
        margin-left:50%;
    }
}
