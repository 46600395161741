.video {
    width: 100%;
    height: auto;
    padding-top: 70%;
    position: relative;

    &__play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 5;
        padding: 1rem;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, .5);
        transition: background-color .3s ease-in-out;

        &:hover {
            background-color: rgba(255, 255, 255, .75);
            color: $primary;
        }

        .icon {
            font-size: 5rem;
        }
    }

    &__placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        z-index: 1;
    }
}
