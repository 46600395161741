.table-wrapper {
    padding: 2rem 0;
    margin: 2rem 0;
}

.binect-table {
    table {
        width: 100%;

        tr {
            &:nth-child(even) {
                background-color: rgba(255, 255, 255, .3);
            }
        }

        td {
            padding: .5rem 1rem;
            font-size: $font-size-md;
            color: $white;
            font-weight: 300;
            text-align: left;
        }
    }

    &__head {
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 4.75rem;
        height: 4.75rem;
        flex-basis: 4.75rem;
    }

    &__headline {
        font-size: $font-size-lg;
        font-weight: 300;
        color: $white;
        margin-left: .5rem;
    }
}
