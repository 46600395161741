.has-icons {
    i {
        display: block;
        text-align: center;
        color: rgb(65,182,230);
        margin-bottom: 5px;
        font-size: 1.5rem;
    }

    .elementor-widget-wrap {
        align-content: center;
    }

    &.grey_bg {
        > div > div > div {
            background-color: #F4F4F4;
        }
    }

    .elementor-column {
        text-align: center;
    }

    .elementor-column:first-of-type {
        text-align: left;
    }
}