.elementor-field .uc-text-embedding[mock="uc-recaptcha-mock"] {
    border: 2px solid $dark-gray;
    padding: 1rem;

    a {
        color: $primary;
        text-decoration: underline;
        display: inline-block;
        height: auto;
    }

    > strong {
        display: block;
        margin-bottom: 1rem;
    }
}

.elementor-column .privacy-label {
    font-weight: 400;
}
