.section {
    &__title {
        &--small {
            color: $dark-gray;
        }
    }

    .section {
        &__navigation {
            margin: 0;
            margin-left: -$section-spacing;

            li {
                padding: 0;

                + li {
                    margin-top: 0;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, .3);
                }

                &:before {
                    top: 13px;
                    left: $section-spacing;
                    font-size: .75rem;
                    content: $icon--arrow-right;
                }

                a {
                    display: inline-block;
                    color: $white;
                    width: 100%;
                    //padding: .65rem 0 .65rem calc(#{$section-spacing} + 2rem);
                }
            }
        }

        &__additional-text {
            position: absolute;
            top: 100%;
            left: 0;
            height: auto;
            width: 100%;
            max-width: 50%;
            padding: 2rem 1rem 0 $section-spacing;
        }
    }

    &--buttons {
        text-align: right;
    }

    &--default-spacing {
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media-breakpoint-down(md) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    &--news {
        @include media-breakpoint-down(md) {
            .row {
                .col-sm-12 + .col-sm-12 {
                    margin-top: 2rem;
                }
            }
        }
    }

    &__element {
        &--primary {
            .button {
                margin-top: 2rem;

                @include media-breakpoint-down(md) {
                    margin-top: 1rem;
                }
            }
        }

        &--secondary {
            .image, .video {
                width: 100%;
                height: auto;
                padding-top: calc(70% + #{$section-spacing});
                position: relative;
            }

            .image {
                background-size: cover;
            }
        }
    }

    &--secondary-primary, &--primary-secondary {
        display: flex;

        .section {
            &__wrapper {
                padding: $section-spacing $section-spacing 0;
                width: 100%;
                max-width: 75%;
                position: relative;

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                    padding: 2rem 1rem;
                }
            }

            &__bg-element {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 70%;
                min-height: 28.5rem;
                background-color: $primary;
                border-radius: $border-radius-default;
                z-index: 9;

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                    height: 100% !important; // Overwrite because of inline javascript height
                    border-radius: 0;
                    min-height: 0;
                }
            }

            &__headline {
                color: $white;

                @include media-breakpoint-down(md) {
                    font-size: $font-size-xl;
                }
            }

            &__headline, &__elements {
                z-index: 10;
                position: relative;
            }

            &__elements {
                margin-top: 2.5rem;
                display: flex;
                flex-wrap: nowrap;
                align-items: flex-start;

                @include media-breakpoint-down(md) {
                    margin-top: 1rem;
                    display: block;

                    > div + div {
                        margin-top: 2rem;
                    }
                }
            }

            &__element {
                &--secondary {
                    width: 90%;
                    min-width: 90%;
                    height: auto;
                    background-color: $light-gray;
                    position: relative;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        min-width: 0;
                    }
                }

                &--primary {
                    width: 100%;
                }
            }

            &__element-interferer {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;

                @include media-breakpoint-down(md) {
                    max-width: 8rem;
                }

                @include media-breakpoint-down(xxl) {
                    max-width: 12rem;
                    margin: 1rem 0 0 1rem;
                }

                @include media-breakpoint-up(xxl) {
                    transform: translateY(-40%) translateX(-30%);
                }
            }
        }
    }

    &--secondary-primary {
        justify-content: flex-end;

        .section__element {
            &--secondary {
                margin-left: calc(((100% * 1.33333333333) * -0.25) - 4rem);

                @include media-breakpoint-down(md) {
                    margin: 0 -1rem;
                    width: calc(100% + 2rem);;
                    min-width: 0;
                }
            }

            &--primary {
                padding-left: 1rem;

                @include media-breakpoint-down(md) {
                    padding: 1rem 0 0 0;
                }
            }
        }
    }

    &--primary-secondary {
        justify-content: flex-start;

        .section__elements {
            flex-direction: row-reverse;
        }

        .section__element {
            &--secondary {
                margin-right: calc(((100% * 1.33333333333) * -0.25) - 4rem)
            }

            &--primary {
                //padding-right: 1rem;
            }
        }
    }

    &--logos {
        position: relative;

        > .container > .row {
            padding-top: 4rem;
        }

        // Transparent overlay to remove white image backgrounds
        @include media-breakpoint-up(lg) {
            &:before {
                content: '';
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 8;
                pointer-events: none;
                background-color: rgba(0, 0, 0, .075);
            }

            &.bg-color--white {
                &:before {
                    background-color: transparent;
                }
            }
        }

        .col {
            &--fulfillment {
                display: flex;
                flex-direction: column;
                align-items: center;

                .section__title {
                    text-align: center;
                }
            }
        }

        > .container > .row > div {
            padding-top: 4rem;
            padding-bottom: 4rem;

            @include media-breakpoint-up(md) {
                > .section__title {
                    width: 100%;
                    padding: 0 1rem;
                }
            }

            @include media-breakpoint-down(sm) {
                > .section__title {
                    width: 100%;
                    display: inline-block;
                }
            }

            &:first-child {
                padding-right: 4rem;

                @include media-breakpoint-down(md) {
                    padding: 2rem 0;
                }
            }

            + div {
                padding-left: 4rem;
                border-left: 1px solid $dark-gray;

                @include media-breakpoint-down(md) {
                    border: none;
                    padding: 2rem 0;
                }
            }
        }
    }
}
